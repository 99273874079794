import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
const getValidatedFactor = (factor)=>parseFloat(factor) || 1;
export const StyledSideBySideParagraphAndTagsHomepageFirstFoldComponent = styled.div`
	display: flex;
	gap: ${({ gap })=>gap};
	min-height: ${({ minHeight })=>minHeight};

	&:not(.vertical-view) {
		margin-left: calc(max((100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / 2 + 32px, 32px));
	}

	&.vertical-view {
		flex-direction: column;
		align-items: center;
		margin: 0 32px;
	}

	.full-paragraph {
		margin-top: ${({ fullParagraphTopMargin })=>fullParagraphTopMargin};
		width: fit-content;
		justify-content: center;
	}

	.tags-and-assets-component {
		flex: 1;

		.tags-cta-container {
			display: flex;
			justify-content: center;

			.cta-wrapper {
				width: fit-content;
				.product-sign-up {
					height: 40px;

					.signup-button {
						height: 40px;
					}
				}
			}
		}
	}

  ${({ resizingFactor, tagsTitleFontSize = "16" })=>`
  .clickable-tags-grid {
    gap: calc(min(20 * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, 20px));
    padding: calc(min(24 * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, 24px));
    border-radius: calc(min(18 * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, 18px));

    .animated-text-component {
      font-size: calc(max(min(${tagsTitleFontSize} * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, ${tagsTitleFontSize}px), 0.8125rem));
    }

    .tags-container {
      grid-gap: calc(min(12 * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, 12px));

      .tag-component {
        font-size: calc(max(min(14 * (100vw / 1920), 0.875rem) * ${getValidatedFactor(resizingFactor)}, 0.75rem));
        width: calc(max(min(100 * (100vw / 1920), 100px) * ${getValidatedFactor(resizingFactor)}, 80px));
        height: calc(max(min(96 * (100vw / 1920), 96px) * ${getValidatedFactor(resizingFactor)}, 76px));

        .checkbox {
          top: calc(max(min(10 * (100vw / 1920), 10px) * ${getValidatedFactor(resizingFactor)}, 7.5px));
          left: calc(max(min(10 * (100vw / 1920), 10px) * ${getValidatedFactor(resizingFactor)}, 7.5px));
          width: calc(max(min(16 * (100vw / 1920), 16px) * ${getValidatedFactor(resizingFactor)}, 12px));
          height: calc(max(min(16 * (100vw / 1920), 16px) * ${getValidatedFactor(resizingFactor)}, 12px));
          border-radius: calc(max(min(4 * (100vw / 1920) * ${getValidatedFactor(resizingFactor)}, 4px), 2.5px));

          .checkmark svg {
            width: calc(max(min(9 * (100vw / 1920), 9px) * ${getValidatedFactor(resizingFactor)}, 7px));
            height: calc(max(min(8 * (100vw / 1920), 8px) * ${getValidatedFactor(resizingFactor)}, 6px));
          }
        }

        .icon {
          svg {
            height: calc(max(min(20 * (100vw / 1920), 20px) * ${getValidatedFactor(resizingFactor)}, 16px));
            width: calc(max(min(20 * (100vw / 1920), 20px) * ${getValidatedFactor(resizingFactor)}, 16px));
          }
        }
      }
    }
  }
	`}
`;
