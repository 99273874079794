import styled from "@emotion/styled";
import { hexToRgba } from "utils/general";
import Colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
export const StyledClustersSignup = styled.div`
  &.cluster-selected {
    animation: breatheOut 0.3s linear;
  }

  .signup-button-wrapper {
    transform: scale(1);

    button.signup-button {
      background-image: ${({ background })=>background};
      color: ${({ textColor })=>textColor};

      &:disabled {
        background-image: ${({ background })=>background};
        color: ${({ textColor })=>textColor};
      }
    }

    .sign-up-combo-with-text-underline {
      .sign-up-combo-wrapper {
        .signup-combo-buttons-button {
          justify-content: center;

          .secondary-button-wrapper {
            .secondary-button {
              .with-underline {
                background: unset;

                .secondary-button-text {
                  padding-left: 8px;
                }
              }
            }

            &:hover {
              .secondary-button {
                .with-underline {
                  .secondary-button-text {
                    transition: color 0.15 ease-in-out;
                    color: ${hexToRgba(Colors[WHITE_COLOR_NAME], 0.8)};
                  }

                  svg path {
                    transition: fill 0.15 ease-in-out;
                    fill: ${hexToRgba(Colors[WHITE_COLOR_NAME], 0.8)};
                  }
                }
              }
            }
          }
        }
      }

      .text-wrapper {
        margin-top: 32px;
        font-size: 0.8125rem;
        text-align: center;
        padding-left: 3px;
      }
    }
  }

  @keyframes breatheOut {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(1.01);
    }
    20% {
      transform: scale(1.02);
    }
    25% {
      transform: scale(1.03);
    }
    32% {
      transform: scale(1.04);
    }
    50% {
      transform: scale(1.05);
    }
    68% {
      transform: scale(1.04);
    }
    75% {
      transform: scale(1.03);
    }
    80% {
      transform: scale(1.02);
    }
    85% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
`;
