import styled from "@emotion/styled";
import Colors from "styles/colors";
import { UI_BORDER_LIGHT_COLOR } from "styles/color-consts";
const TAG_WIDTH = 100;
const TAG_HEIGHT = 96;
export const StyledTagComponent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 8px;
  background-color: ${Colors.White};
  border: 0.6px solid ${Colors[UI_BORDER_LIGHT_COLOR]};
  width: ${TAG_WIDTH}px;
  height: ${TAG_HEIGHT}px;
  cursor: pointer;
  font-size: 0.875rem;
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }

  &.tag-animation {
    animation: breatheOut 0.3s linear;
  }

  .checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  @keyframes breatheOut {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(1.01);
    }
    20% {
      transform: scale(1.02);
    }
    25% {
      transform: scale(1.03);
    }
    32% {
      transform: scale(1.04);
    }
    50% {
      transform: scale(1.05);
    }
    68% {
      transform: scale(1.04);
    }
    75% {
      transform: scale(1.03);
    }
    80% {
      transform: scale(1.02);
    }
    85% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
`;
