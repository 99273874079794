import styled from "@emotion/styled";
import Colors from "styles/colors";
import { UI_BORDER_LIGHT_COLOR } from "styles/color-consts";
export const StyledCheckbox = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  background-color: ${Colors.White};
  border: 1.25px solid ${Colors[UI_BORDER_LIGHT_COLOR]};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkmark {
    opacity: 0;
    display: flex;
    transition: opacity 0.2s ease-in-out;
  }

  ${({ hovered, color })=>hovered && `border-color: ${color}`};
    

  &.checked {
    border-color: ${({ color })=>color};

    .checkmark {
      opacity: 1;
    }
  }
`;
