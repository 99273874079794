import styled from "@emotion/styled";
import { BRAND_WORK_MANAGEMENT_PRIMARY_COLOR } from "styles/color-consts";
import Colors from "styles/colors";
const ANIMATION_DELAY = 0.05;
const ANIMATION_DURATION = 0.5;
const getAnimationStyle = (wordIndex)=>`
  .animated-word:nth-child(${wordIndex + 1}) {
    animation-delay: ${wordIndex * ANIMATION_DELAY}s;
  }
`;
const getWordAnimationsStyle = (words)=>words.map((word, index)=>getAnimationStyle(index)).join("\n");
export const StyledAnimatedTextComponent = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 24px;
  position: relative;

  .animated-word {
    position: relative;
    display: inline-block;
    font-weight: 400;
    animation: textAnimation ${ANIMATION_DURATION}s ease-in-out forwards;
  }

  ${({ words })=>getWordAnimationsStyle(words)};

  @keyframes textAnimation {
    0%, 100% {
      color: black;
    }
    50% {
      color: ${Colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]};
    }
  }
`;
